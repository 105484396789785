import React from "react";
import PropTypes from "prop-types";
import "./Layout.scss";
// import PropTypes from "prop-types";

const Row = props => <div {...props} className={["Layout-Row", props.className].join(" ")}>
    {props.children}
</div>;

const Col = props => <div {...props} className={["Layout-Col", props.className].join(" ")}>
    {props.children}
</div>;

const Toolbar = props => <div {...props} className={["Layout-Toolbar", props.className].join(" ")}>
    {props.children}
</div>;

const Space = props => <div {...props} className={["Layout-Space", props.className].join(" ")}/>

Row.propTypes = {
    className: PropTypes.string,
};

export default {
    Row,
    Col,
    Toolbar,
    Space,
};
