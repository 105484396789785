import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PDate extends React.Component {
    render() {
        let {className, ...otherProps} = this.props;

        return <span className={"input " + className}>
            <span className="d-block flex-auto">
                 <DatePicker
                     placeholderText={this.props.placeholder}
                     {...(this.props.type === 'datetime' ? {
                         timeInputLabel: "Time:",
                         showTimeInput: true,
                         dateFormat: "dd/MM/yyyy H:mm",
                     } : {})}
                     autoComplete="off"
                     {...otherProps}
                 />
            </span>
            <span className="color-text">
                <FontAwesomeIcon icon="calendar" fixedWidth/>
            </span>
        </span>
    }
}

PDate.defaultProps = {
    className: "",
};

PDate.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
};

export default PDate;