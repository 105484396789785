import React, {Component} from "react";
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"
import {bindActionCreators} from "redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment";

import Page from "../components/Page";
import {sections_get} from "../constants/sections";
import Layout from "src/components/Layout/Layout";
import Loading from "src/components/Loading/Loading";
import Table from "src/components/Table/Table";
import Modal from "src/components/Modal/Modal";
import Button from "src/components/Button/Button";
import DocumentList from "src/components/DocumentList/DocumentList";
import Pager from "src/components/Pager/Pager";

import QueryParams from "src/common/QueryParams";
import Api from "src/common/Api";

const section = sections_get("orders");

class PageOrders extends Component {
    constructor(props) {
        super(props);
        let query = QueryParams.parse(props.location.pathname);
        this.state = {
            throw_err: false,

            response: {
                items: [],
                pageSize: 10,
            },
            loading: false,
            filter_show_history: query.sh === "1" || false,
            filter_search_value: query.q || "",
            filter_sort: query.sort || "id",
            popup_docs_id: false,
        };
        this.fetch = this.fetch.bind(this);
        this.search = this.search.bind(this);
        this.sort = this.sort.bind(this);
        this.renderOrders = this.renderOrders.bind(this);
        this.renderSearch = this.renderSearch.bind(this);
        this.renderDocuments = this.renderDocuments.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps, prevState) {
        let last_param_page_json = JSON.stringify(QueryParams.parse(prevProps.location.pathname)),
            new_param_page_json = JSON.stringify(QueryParams.parse(this.props.location.pathname));

        if(last_param_page_json !== new_param_page_json && !this.state.loading) {
            this.fetch();
        }
    }

    fetch() {
        let query_obj = QueryParams.parse(this.props.location.pathname);
        this.setState(
            {loading: true},
            () => {
                const sh = query_obj.sh || "";
                Api.getOrders({
                        size: this.state.response.pageSize,
                        page: query_obj.page || 1,
                        search: query_obj.q || "",
                        delivered: !!sh,
                        sort: query_obj.sort || "id",
                    })
                    .then((response) => this.setState({
                        response: response,
                        loading: false,
                    }))
                    .finally(() => this.setState({loading: false}))
            }
        );
    }

    sort(col, desc) {
        const filter_sort = !!col && (desc ? "-" : "") + (col || "");
        console.log(filter_sort);
        this.setState({filter_sort}, () => this.changePage(this.state.response.pageCurrent));
    }

    search() {
        let query = QueryParams.parse(this.props.location.pathname);
        query.page = false;
        query.sh = this.state.filter_show_history;
        query.q = this.state.filter_search_value;
        query.sort = this.state.filter_sort;
        this.props.history.push(`/orders/${QueryParams.stringify(query)}`);
    }

    changePage(page) {
        let query = QueryParams.parse(this.props.location.pathname);
        query.page = page;
        query.sh = this.state.filter_show_history;
        query.q = this.state.filter_search_value;
        query.sort = this.state.filter_sort !== "id" ? this.state.filter_sort : false;
        query.sort = this.state.filter_sort;
        this.props.history.push(`/orders/${QueryParams.stringify(query)}`);
    }

    renderSearch() {
        return <form
            className="w-100p mt-2r"
            onSubmit={this.search}
        >
            <Layout.Toolbar>
                <div className="flex-auto input">
                    <input
                        className="flex-auto"
                        value={this.state.filter_search_value}
                        onChange={e => this.setState({filter_search_value: e.target.value})}
                        placeholder="Search"
                    />
                    <label className="d-flex align-items-center">
                        <input
                            type="checkbox"
                            className="checkbox mr-8"
                            checked={this.state.filter_show_history}
                            onChange={() => this.setState(state => ({filter_show_history: !state.filter_show_history}))}
                        />
                        Show history
                    </label>
                </div>
                <Button className="mt-0">Search</Button>
            </Layout.Toolbar>
        </form>
    }

    renderOrders() {
        let orders_data = this.state.response.items;
        const desc = this.state.filter_sort[0] === "-",
            filter_sort = this.state.filter_sort;
        // console.log(pageTotal, pageSize, pageCurrent);
        return <>
            <Table
            data={orders_data}
            noDataMessage="No data"
            sorted={{
                column: desc ? filter_sort.substr(1) : filter_sort,
                desc
            }}
            onSort={this.sort}
            columns={[
                {
                    Header: "Order",
                    classTh: "text-nowrap",
                    accessor: "id",
                    Cell: row => <Link to={`/orders/detail/${row.soNo}`}>{row.id}</Link>,
                },
                {
                    Header: "Сustomer Order",
                    accessor: "customerOrder",
                    classTh: "text-nowrap",
                    Cell: row => row.customerOrder,
                },
                {
                    Header: "Order Release Date",
                    accessor: "creationDate",
                    classTh: "text-nowrap",
                    Cell: row => row.creationDate ? moment(row.creationDate).format("YYYY-MM-DD") : row.creationDate,
                },
                {
                    Header: "Delivery Date",
                    accessor: "deliveryDate",
                    classTh: "text-nowrap",
                    Cell: row => row.deliveryDate ? moment(row.deliveryDate).format("YYYY-MM-DD") : row.deliveryDate,
                },
                {
                    Header: "Readiness",
                    classTh: "text-right text-nowrap",
                    accessor: "readiness",
                    sortable: false,
                    Cell: row => <div className="flex-auto text-right">{parseInt(row.readiness, 10) + "%"}</div>,
                },
                {
                    Header: "Documents",
                    classTh: "text-right text-nowrap",
                    accessor: "documents",
                    sortable: false,
                    Cell: row => <div className="flex-auto link text-right" onClick={() => this.setState({selected_id: row.id})}>
                        <FontAwesomeIcon icon="folder-open" fixedWidth/>View
                    </div>,
                },
            ]}
        />
            <Pager
                limit={this.state.response.pageSize}
                offset={(this.state.response.pageCurrent - 1) * this.state.response.pageSize}
                total={this.state.response.pageTotal * this.state.response.pageSize}
                showDetails={false}
                onPrev={offset => this.changePage(offset / this.state.response.pageSize + 1)}
                onNext={offset => this.changePage(offset / this.state.response.pageSize + 1)}
            />
        </>
    }

    renderDocuments() {
        return <Modal
            show={!!this.state.selected_id}
            onClose={() => this.setState({selected_id: undefined})}
        >
            <div className="pt-10 pb-20 ph-20">
                <DocumentList id={this.state.selected_id}/>
            </div>
        </Modal>
    }

    render() {
        return (
            <Page
                title={section.title}
                section={section.code}
                className="pb-4r"
            >
                <Layout.Col>
                    <h1>{section.title}</h1>
                    {this.renderSearch()}
                    <div className="w-100p pos-relative">
                        {this.renderOrders()}
                        {this.state.loading && <Loading place="block"/>}
                    </div>
                </Layout.Col>
                {this.renderDocuments()}
            </Page>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    count: state.global.count,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageOrders))
