import React from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import AuthService from "src/services/AuthService";
import InitLayout from "src/pages/layout/InitLayout";

class AuthLayout extends React.Component {
    render() {
        return <AuthService>
            <InitLayout>
                {this.props.children}
            </InitLayout>
        </AuthService>
    }
}

export const mapStateToProps = (state, ownProps) => ({
    token: state.permanent.token,
});

export default connect(
    mapStateToProps,
)(withRouter(AuthLayout));
