import React from "react";
import {withFormsy} from "formsy-react";
import FBase from "src/forms/components/FBase";

class FCheckbox extends FBase {
    getValue() {
        return !!this.props.getValue();
    }
    changeValue(event) {
        let checked = event.target.checked;

        this.props.setValue(checked);
        this.props.onChange(checked);
    }

    render() {
        return <label className={"d-flex mt-1r " + this.props.className}>
            {this.renderField()}
            {this.props.label && <span className="mr-1r"/>}
            {this.renderLabel()}
            {this.renderHelp()}
            {this.renderError()}
        </label>
    }

    renderField() {
        return <>
            <input
                name={this.props.name}
                checked={this.getValue()}
                onChange={this.changeValue}
                type={"checkbox"}
                className={"checkbox"}
                placeholder={this.props.placeholder}
            />
        </>;
    }
}

FCheckbox.defaultProps = {
    ...FBase.defaultProps,
};

FCheckbox.propTypes = {
    ...FBase.propTypes,
};

export default withFormsy(FCheckbox);
