import React, {Component} from 'react';
import Select from "react-select";
import "./PSelect.scss"
import {FixedSizeList} from "react-window";

const height = 36;

class MenuList extends Component {
    render() {
        const { options, children, maxHeight, getValue } = this.props;
        const [value] = getValue();
        const initialOffset = options.indexOf(value) * height;
        return (
            <FixedSizeList
                height={maxHeight}
                itemCount={children.length}
                itemSize={height}
                initialScrollOffset={initialOffset}
            >
                {({index, style}) => <div style={style} className="PSelect-Item">{children[index]}</div>}
            </FixedSizeList>
        );
    }
}

class PSelect extends React.Component {
    render() {
        let {className, classNamePrefix, ...otherProps} = this.props;

        return <div className={className}>
            <Select
                className={"PSelect"}
                classNamePrefix={"PSelect"}
                components={this.props.options.length > 500 && {MenuList}}
                {...otherProps}
            />
        </div>
    }
}

PSelect.defaultProps = {
    className: "",
    classNamePrefix: "",
};

export default PSelect;
