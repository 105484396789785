import React from "react";
import "./DocumentList.scss";
import PropTypes from "prop-types";

// import Layout from "src/components/Layout/Layout";
import Loading from "src/components/Loading/Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import Helper from "src/common/Helper";
import CSHelper from "src/common/CSHelper";
import Api from "src/common/Api";

class DocumentList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            throw_err: false,
            documents: [],
            loading: true,
        };
    }

    loadDocs = () => {
        this.setState(
            {loading: true},
            () => Api.getOrderDocuments(this.props.id)
            .then(documents => {
                console.log(documents)
                this.setState({documents, loading: false})
            })
        );
    };

    componentDidMount() {
        this.loadDocs();
    }

    componentDidUpdate(prevProps) {
        if(this.props.id && prevProps.id !== this.props.id) this.loadDocs();
    }

    download(path, name) {
        this.setState(
            {loading: true},
            () => Api.getDocument(path, name)
            .finally(() => this.setState({loading: false}))
        )
    }

    render() {
        let groups = CSHelper.groupBy(this.state.documents, 'documentType');
        if(this.state.documents.length === 0) return <p>No documents</p>;
        return(<div className="DocumentList">
            {Object.keys(groups).map((documentType, i) => {
                const documents = groups[documentType];
                return <div key={i}>
                    <h3 id={`${documentType.replace(' ', '-')}s`}>{`${documentType}s`}</h3>
                    {documents.map((doc, j) => <div key={j}>
                        <button className="link text-body d-flex" onClick={() => this.download(doc.link, doc.documentName)}>
                            <div className="pr-4"><FontAwesomeIcon icon="file-pdf" fixedWidth/></div>
                            <div>{doc.documentName}</div>
                        </button>
                    </div>)}
                </div>;
            })}
            {this.state.loading && <Loading place="block"/>}
        </div>);
    }
}

DocumentList.propTypes = {
    id: PropTypes.string,
}

export default DocumentList;
