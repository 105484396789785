import React from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'

import Loading from "src/components/Loading/Loading";
import Error from "src/components/Error/Error";
// import store from "src/store";
// import {PERMANENT_SET_STATE} from "src/modules/permanent";
import Api from "src/common/Api";
import Token from "src/common/Token";

class AuthService extends React.Component {
    state = {
        authorized: false,
        loading: true,
        error: false,
    };

    componentDidMount() {
        this.setState({loading: true}, () => {
            // TODO: Впишите сюда свою проверку авторизации
            // this.setState({loading: false, error: "Some error"}); return false;
            const token = Token.getAccessToken();
            if(token) {
                this.setState({authorized: true, loading: false});
            } else {
                this.props.history.push("/login");
            }
        })
    }

    static logIn = (login, password) => new Promise((resolve, reject) => {
        Api.login(login, password)
            .then(response => {
                Token.setAccessToken(response.token);
                Token.setUser(response.userName);
                resolve(true);
            })
            .catch(error => reject(error))
        ;
    });

    static signOut = () => {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://dankey.dansteel.dk/customers-api/orders?delivered=false&sort=id&size=10&page=1', false, 'a','a');
        xhr.setRequestHeader("Authorization", "Basic xxx");
        xhr.send();
        window.location.href = '/users';
    }

    static logOut = () => {
        Token.clearTokens();
        window.location.href = '/';
    }

    render() {
        const {loading, error, authorized} = this.state;

        if (loading) return <Loading place={"window"} text={"Authorization..."}/>;
        if (error) return <Error text={error}/>;
        if (!authorized) return <Redirect to={this.props.login_page_url}/>;

        return this.props.children;
    }
}

AuthService.defaultProps = {
    login_page_url: "/login",
    checkAuth: Promise.resolve(),
};

AuthService.propTypes = {
    login_page_url: PropTypes.string, // URL на который произойдёт редирект при разлогине
};

export const mapStateToProps = (state, ownProps) => ({
    token: state.permanent.token,
    state: state,
});

export default connect(
    mapStateToProps,
)(withRouter(AuthService))
