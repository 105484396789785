import React, {Component} from "react";
import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"
import {bindActionCreators} from "redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment";

import Page from "../components/Page";
import Layout from "src/components/Layout/Layout";
import Loading from "src/components/Loading/Loading";
import Button from "src/components/Button/Button";
import Table from "src/components/Table/Table";
import DocumentList from "src/components/DocumentList/DocumentList";
import Modal from "src/components/Modal/Modal";

import Helper from "src/common/Helper";
import QueryParams from "src/common/QueryParams";
import Api from "src/common/Api";

class PageOrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            throw_err: false,
            order_loading: false,
            order_data: {
                _items: []
            },
            order_item: {},
            order_item_search_value: "",
            loading: false,
            loading_plates: false,
        };
        this.fetch_order = this.fetch_order.bind(this);
        this.fetch_plates = this.fetch_plates.bind(this);
        this.search = this.search.bind(this);
        this.renderSearch = this.renderSearch.bind(this);
        this.renderDocuments = this.renderDocuments.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.renderPlates = this.renderPlates.bind(this);
    }

    componentDidMount() {
        this.fetch_order();
        this.fetch_plates();
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(QueryParams.parse(prevProps.location.pathname)) !== JSON.stringify(QueryParams.parse(this.props.location.pathname)) && !this.state.plates_loading) {
            this.fetch_plates();
        }
    }

    fetch_order() {
        this.setState(
            {loading: true},
            () => Api.getOrder(this.props.match.params.order_id)
                .then(order_data => this.setState({
                    loading: false,
                    order_data: {...order_data, _items: Helper.copy_obj(order_data.items)}
                }))
                .finally(() => this.setState({loading: false}))
        );
    };

    fetch_plates() {
        let query = QueryParams.parse(this.props.location.pathname);
        if(!query.oi) return false;
        this.setState(
            {loading_plates: true},
            () => Api.getOrderItem(query.oi)
                .then(order_item => this.setState({order_item}))
                .finally(() => this.setState({loading_plates: false}))
        )
    };

    search(e) {
        e.preventDefault();
        let search_val = String(this.state.order_item_search_value).toLowerCase(),
            _items = Helper.copy_obj(this.state.order_data.items).filter(item => {
                return String(item.status).toLowerCase().indexOf(search_val) > -1
                    || String(item.quality).toLowerCase().indexOf(search_val) > -1
                    || String(item.length).toLowerCase().indexOf(search_val) > -1
                    || String(item.id).toLowerCase().indexOf(search_val) > -1
                    || String(item.thick).toLowerCase().indexOf(search_val) > -1
                    || String(item.weight).toLowerCase().indexOf(search_val) > -1
                    || String(item.width).toLowerCase().indexOf(search_val) > -1
            });
        this.setState({
            order_data: {
                ...this.state.order_data,
                _items: _items,
            }
        })
    };


    renderSearch() {
        return <form
            className="w-100p mt-2r"
            onSubmit={this.search}
        >
            <Layout.Toolbar>
                <input
                    className="flex-auto input"
                    value={this.state.order_item_search_value}
                    onChange={e => this.setState({order_item_search_value: e.target.value})}
                    placeholder="Search"
                />
                <Button className="mt-0">Search</Button>
            </Layout.Toolbar>
        </form>
    }

    renderDocuments() {
        return <Modal
            clickContent={
                <div className="mt-20 d-flex link">
                    <div className="pr-4"><FontAwesomeIcon icon="folder-open" fixedWidth/></div>
                    Documents
                </div>
            }
        >
            <div className="pt-10 pb-20 ph-20">
                <DocumentList id={this.state.order_data.id}/>
            </div>
        </Modal>
    }

    renderItems() {
        const classTh = "text-nowrap text-align-right";
        return <Table
            data={this.state.order_data._items}
            initialSort={{column: "id", desc: false}}
            columns={[
                {
                    Header: "Order-Item",
                    accessor: "id",
                    classTh: "text-nowrap",
                    Cell: row => <div className="text-nowrap">{row.id}</div>,
                },
                {
                    Header: "Mass",
                    accessor: "weight",
                    classTh,
                    sortable: false,
                    Cell: row => <div className="ml-a">{row.weight}</div>
                },
                {
                    Header: "Thickness",
                    accessor: "thick",
                    classTh,
                    sortable: false,
                    Cell: row => <div className="ml-a">{row.thick}</div>
                },
                {
                    Header: "Length",
                    accessor: "length",
                    classTh,
                    sortable: false,
                    Cell: row => <div className="ml-a">{row.length}</div>
                },
                {
                    Header: "Width",
                    accessor: "width",
                    classTh,
                    sortable: false,
                    Cell: row => <div className="ml-a">{row.width}</div>
                },
                {
                    Header: "Quality",
                    accessor: "quality",
                    classTh,
                    sortable: false,
                    Cell: row => <div>
                        {
                            row.quality && row.quality
                                .split(";")
                                .map((qnt, i) => <div key={i}>{qnt}</div>)
                        }
                    </div>
                },
                {
                    Header: "EXW Delivery Week",
                    accessor: "deliveryWeek",
                    classTh: "text-nowrap",
                    sortable: false,
                },
                {
                    Header: "Status",
                    accessor: "status",
                    classTh: "text-nowrap",
                    sortable: false,
                },
                {
                    Header: "Item Total",
                    accessor: "countAll",
                    classTh,
                    sortable: false,
                    Cell: row => <div className="ml-a">{row.countAll}</div>
                },
                {
                    Header: "In production",
                    accessor: "countInProd",
                    classTh,
                    sortable: false,
                    Cell: row => <div className="ml-a">{row.countInProd}</div>
                },
                {
                    Header: "Ready for dispatch",
                    accessor: "countReady",
                    classTh,
                    sortable: false,
                    Cell: row => <div className="ml-a">{row.countReady}</div>
                },
                {
                    Header: "Dispatched",
                    accessor: "countDispatch",
                    classTh,
                    sortable: false,
                    Cell: row => <Link className="ml-a" to={`/orders/detail/${this.state.order_data.soNo}/oi=${row.soiNo}/`}>{row.countDispatch}</Link>
                },
            ]}
        />;
    }

    renderPlates() {
        if (!this.state.order_item.plates || !this.state.order_item.plates.length) return false;
        return <>
            <h2>Order plates</h2>
            {this.state.loading_plates && <Loading place="block"/>}
            <Table
                data={this.state.order_item.plates}
                columns={[
                    {
                        Header: "ID",
                        accessor: "id",
                        Cell: row => row.id,
                    },
                    {
                        Header: "Dispatched Date",
                        accessor: "dispatchedDate",
                        Cell: row => row.dispatchedDate ? moment(row.dispatchedDate).format("YYYY-MM-DD") : row.dispatchedDate,
                    },
                ]}
            />
        </>;
    }

    render() {
        const customerName = this.state.order_data.customerName || "",
            header = "Order " + this.state.order_data.id + (customerName && " — " + customerName);
        return (
            <Page
                title={header}
                className="pb-4r"
            >
                <Layout.Col>
                    <h1>{header}</h1>
                    {this.renderDocuments()}
                    {this.renderSearch()}
                    <div className="w-100p pos-relative">
                        {this.renderItems()}
                        {this.state.loading && <Loading place="block"/>}
                    </div>
                    <div className="w-100p pos-relative">
                        {this.renderPlates()}
                    </div>
                </Layout.Col>
            </Page>
        );
    }
}

PageOrderDetail.defaultProps = {
    id: "9283841"
}

const mapStateToProps = (state, ownProps) => ({
    count: state.global.count,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageOrderDetail))
