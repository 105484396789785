import Token from "src/common/Token";
const api_url = process.env.REACT_APP_API_HOST;

class Api {
    // Authorization
    static login = (userNick, userPass) => {
        return query("POST", `${api_url}customers-api/login`, {userNick, userPass});
    };

    // Orders
    static getOrders = (params) => default_response(window.client.apis.Orders.GetOrders(params));
    static getOrder = (orderId) => default_response(window.client.apis.Orders.GetOrder({orderId}));
    static getOrderItem = (itemId) => default_response(window.client.apis.Orders.GetOrderItem({itemId}));
    static getOrderDocuments = (orderId) => default_response(window.client.apis.Orders.GetOrderDocuments({orderId}));

    // Customers
    static getCustomers = () => default_response(window.client.apis.Customers.GetCustomers());

    // Users
    static getUsers = () => default_response(window.client.apis.Users.GetUsers());
    static createUser = (requestBody) => default_response(window.client.apis.Users.CreateUser({}, {requestBody}));
    static deleteUser = (userId) => default_response(window.client.apis.Users.DeleteUser({userId}));
    static changeUser = (userId, requestBody) => default_response(window.client.apis.Users.ChangeUser({userId}, {requestBody}));

    // Documens
    static getDocument = (filePath, fileName) => query_file(filePath, fileName);
}

export const default_response = (promise) => new Promise((resolve, reject) => {
    promise
        .then(result => {
            // здесь можно каким-то образом подготовить ответ со ВСЕХ запросов проекта
            // а так же вызвать reject() (допустим при наличии в ответе is_error===true
            resolve(result.obj)
        })
        .catch((error) => {
            // здесь можно написать свою глобальную обработку ошибок для всех запросов приложения
            if(error.status === 401 && Token.getAccessToken()) {
                Token.clearTokens();
                window.location.href = '/';
                return false;
            }
            console.log('error', error);
            reject(error);
        })
});

export const query = (method, url, body, dataType = "JSON") => {
    return default_response(
        new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open(method, url);

            xhr.setRequestHeader('Authorization', `Bearer ${Token.getAccessToken()}`);
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if(xhr.status === 403) {
                        reject(xhr);
                        return false;
                    }
                    try {
                        const response = JSON.parse(xhr.response);
                        // if(!response.is_error) resolve(response);
                        // else reject(response);
                        resolve({obj: response});
                        // обернуто в {obj: ...} по аналогии с клиентской библиотекой сваггера, что бы можно было
                        // использовать один default_response на разные типы запросов
                    } catch (e) {
                        console.log('e', e);
                        reject(e);
                    }

                }
            };

            let send_body;
            switch (dataType) {
                case "formData":
                    send_body = new FormData();
                    Object.keys(body).forEach(key => {
                        send_body.append(key, body[key]);
                    });
                    break;
                case "JSON":
                default:
                    xhr.setRequestHeader("Content-Type", "application/json");
                    send_body = JSON.stringify(body);
                    break;
            }
            xhr.send(send_body, true);
        })
    );
};

export const query_file = (filePath, fileName) => new Promise ((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", filePath);
    xhr.setRequestHeader('Authorization', `Bearer ${Token.getAccessToken()}`);
    xhr.responseType = "blob";
    xhr.onreadystatechange = () => {
        if(xhr.readyState === XMLHttpRequest.DONE) {
            if(xhr.status === 401) {reject("Токен истёк"); return false}
            try {
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(xhr.response, fileName);
                    resolve();
                }
                let parsedName = xhr.getResponseHeader("Content-Disposition").split('"')[1];
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(xhr.response);
                link.download = fileName || parsedName;
                document.body.appendChild(link);
                link.click();
                link.remove();
                resolve();
            } catch (e) {
                console.log('e', e);
                reject(e);
            }
        }
    };
    xhr.send("", true);
});

export default Api;
