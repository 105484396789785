import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./Pager.scss";

class Pager extends React.Component {
    render() {
        const {className, showDetails, total, limit, offset, onNext, onPrev} = this.props;
        if (!(total > limit)) return false;

        const classNames = ["Pager"];
        if(className) classNames.push(className);


        return <div className={classNames.join(" ")}>
            {showDetails &&
            <div className="Pager-Items">
                {offset + 1}—{
                ((offset + limit) < total)
                    ? offset + limit
                    : total
            } of {total}
            </div>}
            <div className={"Pager-Control"}>
                <button className={"Pager-Button Pager-Button_left"}
                        disabled={((offset / limit) === 0)}
                        onClick={() => onPrev(offset - limit, limit)}
                >
                    <FontAwesomeIcon icon={"chevron-left"} fixedWidth/>
                </button>
                <div>
                    {offset / limit + 1}
                    <span className="Pager-Separator">/</span>
                    {Math.ceil(total / limit)}
                </div>
                <button className={"Pager-Button Pager-Button_right"}
                        disabled={(offset / limit + 1) === Math.ceil(total / limit)}
                        onClick={() => onNext(offset + limit, limit)}
                >
                    <FontAwesomeIcon icon={"chevron-right"} fixedWidth/>
                </button>
            </div>
        </div>;
    }
}

Pager.defaultProps = {
    offset: 0,
    limit: 0,
    total: 0,
    onPrev: (offset, limit)=>{},
    onNext: (offset, limit)=>{},
    showDetails: true
};

Pager.propTypes = {
    className: PropTypes.string,
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    showDetails: PropTypes.bool
};

export default Pager;
