import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux'
import {sections} from "../../constants/sections";
import Layout from "src/components/Layout/Layout";
import Token from "src/common/Token";
import AuthService from "src/services/AuthService";

class Header extends React.Component {
    render() {
        const authorized = window.location.pathname.split("/")[1] !== "users";
        const user = Token.getUser();
        return <>
            <div className={"shadow-l no-select w-100p bg-window"}>
                <Layout.Row className="pv-1r">
                    <Layout.Toolbar>
                        {sections.map((section, i) => {
                            if(authorized === section.authAccess)
                                return <NavLink
                                    to={section.path}
                                    exact
                                    isActive={() => this.props.section === section.code}
                                    key={i}
                                    activeClassName={"color-text bg-button-bg radius-s no-click"}
                                    className={"ph-4 no-select"}
                                >{section.name}</NavLink>;
                            return false;
                        })}
                        <Layout.Space/>
                        <div
                            className="link"
                            onClick={() => authorized ? AuthService.logOut() : AuthService.signOut()}
                        >
                            Log out {authorized && user}
                        </div>
                    </Layout.Toolbar>
                </Layout.Row>
            </div>
        </>;
    }
}

export default connect((state, ownProps) => ({
    section: state.global.section,
}),)(Header)
