import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Helper from "src/common/Helper";
import "./Table.scss";
import Pager from "../Pager/Pager";

const columnDefaults = {
    sortable: true,
};

class Table extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.getInitState(props);

        this.getInitState = this.getInitState.bind(this);
        this.getColumns = this.getColumns.bind(this);

        this.sortData = this.sortData.bind(this);
        this.doSort = this.doSort.bind(this);

        this.onSelect = this.onSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onMove = this.onMove.bind(this);

        this.renderHead = this.renderHead.bind(this);
        this.renderBody = this.renderBody.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
    }
    getInitState(props = this.props) {
        return {
            columns: this.getColumns(props.columns),
            data: Helper.copy_obj(props.data),

            selected: [],
            selectedRows: [],
            selectedAll: false,

            sortCol: this.props.initialSort ? this.props.initialSort.column : false,
            sortDesc: this.props.initialSort ? this.props.initialSort.desc : false,

            dragFrom: -1,
            dragOver: -1,
            dragOverPrev: -1,

            pageActive: props.pageActive || 1,
            pageSize: props.pageSize,
        };
    }
    getColumns(columns) {
        columns = columns.map(column => {
            column = Helper.objects_merge(this.props.columnDefaults, column);
            return column;
        });
        return columns;
    }
    componentDidUpdate(prevProps) {
        if(prevProps.reload !== this.props.reload) {

            this.setState(this.getInitState())
        }
    }

    doSort(accessor, desc = this.state.sortDesc) {
        if (this.props.allowUnsorted) {
            if (desc && accessor === this.state.sortCol) accessor = false;
            desc = accessor === this.state.sortCol ? !this.state.sortDesc : false;
        } else {
            if (accessor === this.state.sortCol) desc = !this.state.sortDesc;
            else desc = false;
        }

        this.setState({
            sortCol: accessor,
            sortDesc: desc,
            pageActive: 1,
        });

        if(this.props.onSort) this.props.onSort(accessor, desc);
    }
    sortData(data = Helper.copy_obj(this.state.data)) {
        const {sortCol, sortDesc} = this.state;

        if(!sortCol) return data;

        data = data.sort((a, b) => {
            let result = 0;
            if(a[sortCol] > b[sortCol]) result = 1;
            else if(a[sortCol] < b[sortCol]) result = -1;
            return sortDesc ? 0 - result : result;
        });

        return data;
    }

    rowClass(row, index) {
        let resultClass = "Table-Row";
        if (this.state.dragFrom !== -1) {
            if ((index === this.state.dragFrom)) resultClass += " Table-Row_on-drag";
            if ((index !== this.state.dragFrom) && (index === this.state.dragOver)) {
                resultClass += " Table-Row_drop-zone";
                // if (index < this.state.dragFrom)
                //     resultClass += " Table-Row_drop-before";
                // else
                //     resultClass += " Table-Row_drop-after";
            }
        }
        return resultClass;
    }

    onSelect(row) {
        let {selected, selectedRows, selectedAll} = this.state;
        const {columnAccessorId, data} = this.props;

        const index = selected.indexOf(row[columnAccessorId]);

        if(index > -1) {
            selected.splice(index, 1);
            selectedRows.splice(index, 1);
            selectedAll = false;
        } else {
            selected.push(row[columnAccessorId]);
            selectedRows.push(row);
            selectedAll = data.length === selected.length;
        }

        this.setState({selected, selectedRows, selectedAll},
            () => this.props.onSelect(row, selected, selectedRows));
    }
    onSelectAll() {
        let {selectedAll, selected, selectedRows} = this.state,
            {columnAccessorId, data} = this.props;

        if (selectedAll) {
            selectedAll = false;
            selected = [];
            selectedRows = [];
        } else {
            selectedAll = true;
            selected = data.map((item) => item[columnAccessorId]);
            selectedRows = Helper.copy_obj(data);
        }

        this.setState({selectedAll, selected, selectedRows},
            () => this.props.onSelectAll(selected, selectedRows));
    }

    onMove(start, end, row_start, row_end) {
        this.props.onMove(start, end, row_start, row_end);
        // обновленная data должна приходить в props
        // ниже код замены местами элементов
        // if(end > start) {
        //     data.splice(end + 1, 0, row_start);
        //     data.splice(start, 1);
        // } else if(end < start) {
        //     data.splice(start, 1);
        //     data.splice(end, 0, row_start);
        // }
    }

    render() {
        const data = this.props.data;

        if(!data || data.length === 0) {
            return <div className={"Table-NotFound"}>
                {this.props.noDataMessage}
            </div>;
        }
        return <div className="Table">
            <table className={"Table-Content"}>
                {this.renderHead()}
                {this.renderBody()}
            </table>
            {this.renderPagination()}
        </div>;
    }

    renderHead() {
        const {columns, sortCol, sortDesc} = this.state,
            {canSelect, canSelectAll} = this.props;
        return <thead>
        <tr>
            {/* Столбец выделения строк */}
            {canSelect && <th className="Table-Cell Table-Cell_header Table-Cell_checkbox">
                {canSelect && canSelectAll &&
                <input
                    className="checkbox"
                    type={"checkbox"}
                    checked={this.state.selectedAll}
                    onChange={this.onSelectAll}
                />}
            </th>}
            {columns.map((column, key) => {
            let classNames = ["Table-Cell", "Table-Cell_header"];
            if(column.classTh) classNames.push(column.classTh);
            if(column.sortable) classNames.push("Table-Cell_sort");

            return <th key={key} className={classNames.join(" ")} style={column.styleTh || {}}>
                <div className={column.sortable ? "Table-SortLabel" : ""} onClick={() => column.sortable && this.doSort(column.accessor)}>
                    {column.Header}
                    {column.sortable &&
                        <FontAwesomeIcon
                            fixedWidth
                            className={[
                                "Table-Sort",
                                column.sortable && !sortCol && "Table-Sort_unsorted",
                                sortCol === column.accessor && "Table-Sort_sorted",
                            ].join(" ")}
                            icon={
                                sortCol === column.accessor
                                ? (sortDesc ? "sort-down" : "sort-up")
                                : "sort"
                            }
                        />
                    }
                </div>
            </th>})}
        </tr>
        </thead>
    }
    renderBody() {
        let {selected, pageSize, pageActive} = this.state,
            {columnAccessorId, data} = this.props;

        data = Helper.copy_obj(data);

        data = this.props.onSort ? data : this.sortData(data);

        if(pageSize) {
            data = data.splice((pageActive - 1) * pageSize, pageSize);
        }

        return <tbody>
        {data.map((row, index) => {
            return <tr
                key={row[columnAccessorId]}
                className={this.rowClass(row, index)}
                onDragOver={e => e.preventDefault()}
                onDragEnter={() => this.setState({dragOver: index})}
                onDragLeave={() => this.setState({dragOverPrev: index})}
                onDrop={e => {
                    e.preventDefault();
                    if(this.state.dragFrom === index) return false;
                    this.onMove(this.state.dragFrom, this.state.dragOver, data[this.state.dragFrom], data[this.state.dragOver]);
                }}
            >
                {
                    this.props.canSelect &&
                    <td className="Table-Cell">
                        <input
                            className="checkbox"
                            type={"checkbox"}
                            checked={selected.indexOf(row[columnAccessorId]) > -1}
                            onChange={this.onSelect.bind(this, row)}
                            onClick={e => e.stopPropagation()}
                        />
                    </td>
                }
                {
                    this.props.columns.map((col, subindex) => <td colSpan={col.colspan || null}
                        className={["Table-Cell", col.className || ""].join(" ")}
                        key={subindex}>
                        <div className="Table-Draggable">
                            {
                                (typeof col.Cell === "function")
                                    ? col.Cell(row, index, col)
                                    : col.Cell || row[col.accessor]
                            }
                            {this.props.canMove && !row._not_drag && (subindex === this.props.columns.length - 1) &&
                            <div
                                className="Table-DragControl"
                                draggable={this.props.canMove}
                                onDragStart={(e) => {
                                    e.dataTransfer.setData("text", "foo");
                                    if (row.groupId) this.setState({dragFrom: index, dragOver: -1, dragGroup: row.groupId});
                                    else this.setState({dragFrom: index, dragOver: -1});
                                }}
                                onDragEnd={e => {
                                    e.preventDefault();
                                    this.setState({dragFrom: -1, dragOver: -1, dragOverPrev: -1, dragGroup: false})
                                }}
                            >
                                <FontAwesomeIcon icon={"grip-lines"}/>
                            </div>
                            }
                        </div>
                    </td>)
                }
            </tr>
        })}
        </tbody>
    }
    renderPagination() {
        const {data, pageActive} = this.state,
            {pageSize} = this.props;

        if(!pageSize) return  false;

        return <Pager
            offset={pageSize * pageActive - pageSize}
            limit={pageSize}
            total={data.length}
            onNext={(offset, limit) => {this.setState({pageActive: offset / pageSize + 1})}}
            onPrev={(offset, limit) => {this.setState({pageActive: offset / pageSize + 1})}}
        />;
    }
}

Table.defaultProps = {
    noDataMessage: "Нет данных",
    columnDefaults: columnDefaults,
    columnAccessorId: "id",

    canSelect: false,
    canSelectAll: false,
    onSelect: (row, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows) => {},

    allowUnsorted: true,

    reload: 0,
};

const columnModel = PropTypes.shape({
    classTh: PropTypes.string,
    classTd: PropTypes.string,
    styleTh: PropTypes.object,
    styleTd: PropTypes.object,

    // Описывает что отображать в заголовке колонки. Если не задано - ничего не отобразится.
    // (иконка сортировки рисуется отдельно от Header и не может быть отменена в этом поле)
    // Может быть задано как функция (без аргументов)
    Header: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
    // Описывает что отображать в ячейке. Если не задано - отобразится row[accessor]
    // Если задано как функция, то принимает аргумент row (объект строки). Например: Cell: row => `ID = ${row.id}`
    Cell: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
    accessor: PropTypes.string, // Строковый идентификатор колонки (в рамках одной таблицы).
    sortable: PropTypes.bool, // Можно ли сортировать по этой колонке. По умолчанию - true
});

Table.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(columnModel).isRequired,

    noDataMessage: PropTypes.string, // Сообщение которое покажется если данных нет
    columnAccessorId: PropTypes.string, // Используется для идентификации строк, если не задан - берется "id"

    canSelect: PropTypes.bool, // Можно ли выделять строки. По умолчанию - false
    canSelectAll: PropTypes.bool, // Можно ли выделить все строки. По умолчанию - true
    onSelect: PropTypes.func, // Вызывается при выборе строки, (row, checked) => {} // checked значение которое получило строка true или false
    onSelectAll: PropTypes.func, // (checked) => {..some code}

    // Вызывается при нажатии на заголовок. В аргументах передаются код колонки и направление сортировки.
    // пример: onSort: (column, direction) => {..some code}
    // ВАЖНО: Если onSort ЗАДАНА, то сортировка будет производиться в JS
    // todo: если потребуется вызывать callback, то стоит создать пропс onSortBefore и вызывать его
    onSort: PropTypes.func,
    initialSort: PropTypes.shape({
        column: PropTypes.string,
        desc: PropTypes.bool,
    }),
    allowUnsorted: PropTypes.bool, // Если true, то при сортировке будет промежуточное состояние null:  asc -> desc -> null

    canSearch: PropTypes.bool, // Есть ли элемент с лупой над таблицей. Можно ли искать по таблице. По умолчанию - false
    onSearch: PropTypes.func, // onSearch: (search_string) => {...some code}
    canAdvanced: PropTypes.bool, // Расширениный поиск для search
    onAdvancedOpen: PropTypes.func, // Расширениный поиск для search

    // Drag & Drop
    canMove: PropTypes.bool, // Можно ли перетаскивать строки. По умолчанию - false
    // (start, end, row_start, row_end) => {...some code} // Индексы первоначального нахождения элемента и конечного
    // Если вернёт Promise, то строки переместятся только после resolve.
    // Если вернёт НЕ Promise, то строки переместятся моментально
    onMove: PropTypes.func,

    pageSize: PropTypes.number, // Если не задано - пагинация не производится
    pageActive: PropTypes.number, // Номер активной страницы (начинается с 1)

    reload: PropTypes.number, // Передать (+ new Date()) что бы ресетнуть таблицу
};

export default Table;
