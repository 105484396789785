import Helper from "./Helper";

class QueryParams {

    /**
     * @param url string /pv/variants/page=1/gradeId=S011/qualityId=1019051511800000030/recipeId=106,2/groupId=2/
     * @param context object Объект с ключами arrays (ключи, которые должны возвращаться как массивы (отделяются запятой))
     * @returns object {*}
     */
    static parse = (url, context = {
        arrays: [],
    }) => {
        if(typeof url !== 'string') return {};

        let result = {};
        let query_params = url.split('/').filter(param => param && param.length);
        query_params.forEach(query_param => {
            let parts = query_param.split("=");
            if(parts.length === 2) {
                let key = parts[0],
                    value = parts[1];

                result[key] = context.arrays.indexOf(key) > -1 ? value.split(",") : value;
            }
        });
        return result;
    };
    static stringify = (obj) => {
        let result = '';
        for (let key in obj) {
            let value = obj[key];
            if(typeof value !== 'string' && typeof value !== 'number') {
                value = Helper.implode(',', value);
            }
            if(value && value !== '') {
                result += `${key}=${value}/`;
            }
        }
        return result;
    };
}

export default QueryParams;