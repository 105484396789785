import React from "react";
import {Provider} from "react-redux"
import {BrowserRouter, Switch, Redirect} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react"
import store from "src/store"

import AppRoute from "src/pages/layout/AppRoute";
import InitLayout from "src/pages/layout/InitLayout";
import AuthLayout from "src/pages/layout/AuthLayout";
import LoginLayout from "src/pages/layout/LoginLayout";

import PageLogin from "src/pages/PageLogin";
import PageOrders from "src/pages/PageOrders";
import PageUsers from "src/pages/PageUsers";
import PageOrderDetail from "src/pages/PageOrderDetail";
import Page404 from "src/pages/Page404";
import {persistor} from "./persistor";

const Routes = <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
            <Switch>
                {/* Авторизация */}
                <AppRoute layout={LoginLayout} exact path={"/login"} component={PageLogin}/>

                {/* Рабочий интерфейс */}
                <AppRoute layout={InitLayout} exact path={"/"} component={() => <Redirect to={"/orders"}/>}/>
                <AppRoute layout={AuthLayout} exact path={"/orders/detail/:order_id/:filter1?"} component={PageOrderDetail}/>
                <AppRoute layout={AuthLayout} exact path={"/orders/:filter1?/:filter2?/:filter3?/"} component={PageOrders}/>
                <AppRoute layout={InitLayout} exact path={"/users/:filter1?/:filter2?/:filter3?/"} component={PageUsers}/>

                <AppRoute layout={InitLayout} path={"*"} component={Page404}/>
            </Switch>
        </BrowserRouter>
    </PersistGate>
</Provider>;

export default Routes;

// Небольшой костыль что бы избавиться от надоедливого варнинга
// https://github.com/nfl/react-helmet/issues/426#issuecomment-547583689
if (process.env.NODE_ENV === "development") {
    const ignorePatterns = [
        "Warning: componentWillMount",
        "Warning: componentWillReceiveProps",
        "Warning: componentWillUpdate ",
        "SideEffect(NullComponent)",
    ];

    const { warn } = console;

    console.warn = function (...labels) {
        if (ignorePatterns.every((i) => !labels[0].includes(i))) {
            warn(...labels);
        }
    };
}
