import React from "react";
import Select from "react-select";
import {withFormsy} from "formsy-react";
import FBase from "src/forms/components/FBase";
import PSelect from "src/forms/primitives/PSelect";

class FSelect extends FBase {
    getValue() {
        const map = this.props.options_map,
            options = this.props.options,
            value = this.props.getValue();

        if(value in map) return options[map[value]];
        if(value && value[0]) return value.map(option => options[map[option]]);
        return null;
    }
    changeValue(opt) {
        let value = opt ? opt.value : opt;
        if(this.props.isMulti) value = opt.map(option => option.value);
        this.props.setValue(value);
        this.props.onChange(value);
    }
    renderField() {
        return <PSelect
            name={this.props.name}
            value={this.getValue()}
            onChange={this.changeValue}
            placeholder={this.props.placeholder}

            options={this.props.options}

            isMulti={this.props.isMulti}
            isClearable={this.props.isClearable}
        />;
    }
}

FSelect.defaultProps = {
    ...FBase.defaultProps,
    options: [],
    options_map: {},
};

FSelect.propTypes = {
    ...FBase.propTypes,
    ...Select.propTypes,
};

export default withFormsy(FSelect);
