import React, {Component} from 'react';
import { withRouter, Link} from 'react-router-dom'
import Page from "../components/Page";
import Layout from "src/components/Layout/Layout";

class Page404 extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Page
                title={"404. Not found"}
                className="pb-4r"
            >
                <Layout.Col>
                    <h1>Requested page doesn't exist</h1>
                    <p><Link to={"/"}>Go home</Link></p>
                </Layout.Col>
            </Page>
        );
    }
}

export default withRouter(Page404);
