const accessToken = 'accessToken',
    refreshToken = 'refreshToken',
    tokenUser = 'tokenUser';

class Token {
    static getAccessToken() {
        return localStorage.getItem(accessToken);
    }

    static getRefreshToken() {
        return localStorage.getItem(refreshToken);
    }

    static setAccessToken(token) {
        localStorage.setItem(accessToken, token);
        return true;
    }

    static setRefreshToken(token) {
        localStorage.setItem(refreshToken, token);
        return true;
    }

    static clearTokens() {
        localStorage.removeItem(accessToken);
        localStorage.removeItem(refreshToken);
        localStorage.removeItem(tokenUser);
        return true;
    }

    static setUser(userName) {
        localStorage.setItem(tokenUser, userName);
        return true;
    }

    static getUser() {
        return localStorage.getItem(tokenUser);
    }
}

export default Token;
