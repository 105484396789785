import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./Modal.scss";

class Modal extends React.Component {
    static Header = (props) => <div className={"Modal-Header"}>{props.children}</div>;
    static Content = (props) => <div className={"Modal-Content"}>{props.children}</div>;
    static Footer = (props) => <div className={"Modal-Footer"}>{props.children}</div>;

    constructor(props) {
        super(props);

        this.state = {
            show: props.show,
        };

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.renderClickContent = this.renderClickContent.bind(this);
        this.renderModal = this.renderModal.bind(this);
        this.renderClose = this.renderClose.bind(this);
    }

    open() {
        this.setState({show: true}, this.props.onOpen);
    }
    close() {
        this.setState({show: false}, this.props.onClose);
    }

    render() {
        return <>
            {this.renderClickContent()}
            {this.renderModal()}
        </>;
    }
    renderClickContent() {
        const {clickContent} = this.props;
        if(!clickContent) return false;

        return <div className={"Modal-ClickContent"} onClick={this.open}>{clickContent}</div>
    }
    renderClose() {
        if(!this.props.showClose) return false;
        return <div className="Modal-Close" onClick={this.close}>
            <FontAwesomeIcon icon="times"/>
        </div>;
    }
    renderModal() {
        const show = this.props.show !== undefined ?
            this.props.show : this.state.show;

        if (!show) return false;

        return <div className={"Modal"}>
            <div className={"Modal-Frame"}>
                <div className={"Modal-Overlay"} onClick={this.close}/>
                <div className={"Modal-Window"}>
                    {this.renderClose()}
                    {this.props.children}
                </div>
            </div>
        </div>
    }
}

Modal.defaultProps = {
    onOpen: () => {},
    onClose: () => {},
    showClose: true,
};

Modal.propTypes = {
    show: PropTypes.bool, // Если не задано, то должен быть задан clickContent
    clickContent: PropTypes.element, // При клике на этот элемент открывается модал

    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    showClose: PropTypes.bool,

    header: PropTypes.any,
    content: PropTypes.any,
    footer: PropTypes.any,
};

export default Modal;
