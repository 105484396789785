import React, {Component} from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import {bindActionCreators} from 'redux'
import Page from "../components/Page";
import {GLOBAL_SET_STATE} from "../modules/global";
import AuthService from "../services/AuthService";
import Form from "../forms/Form";
import Button from "src/components/Button/Button";
import Layout from "src/components/Layout/Layout";

class PageLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
        };

        this.logIn = this.logIn.bind(this);
    }
    logIn(fields) {
        this.setState(
            {loading: true},
            () => AuthService.logIn(fields.login, fields.password)
                .then(() => this.props.history.push("/"))
                .catch(error => this.setState({error: true}))
                .finally(() => this.setState({loading: false}))
        );
    }
    render() {
        return <Page title={"Login"}>
            <div className="d-flex w-100vw h-100vh">
                <Form
                    name={"test"}
                    className={"m-a w-30r"}
                    onValidSubmit={this.logIn}
                    onChange={() => this.setState({error: false})}
                >
                    <Form.Fields>
                        {[
                            {type: "text", name: "login", label: "Login", required: true},
                            {type: "password", name: "password", label: "Password", required: true},
                        ]}
                    </Form.Fields>
                    <Layout.Toolbar className="mt-1r">
                        <Button>Log in</Button>
                        <div className="color-warning">{this.state.error && "Failed: invalid login or password."}</div>
                    </Layout.Toolbar>
                </Form>
            </div>
        </Page>;
    }
}

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
    set_authorized: () => dispatch({type: GLOBAL_SET_STATE, state: {authorized: true}}),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLogin))
