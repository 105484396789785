import React from "react";
import "./Button.scss";
import PropTypes from "prop-types";

class Button extends React.Component {
    render() {
        const {minWidth, className, ...props} = this.props;
        return(
            <button className={["Button", minWidth && "Button_min", className].join(" ")} {...props}>
                {this.props.children}
            </button>
        );
    }
}
Button.defaultProps = {
    className: "",
    minWidth: false,
}
Button.propTypes = {
    className: PropTypes.string,
    minWidth: PropTypes.bool,
}

export default Button;
