import React, {Component} from "react";
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"
import {bindActionCreators} from "redux"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Page from "../components/Page";
import {sections_get} from "../constants/sections";
import Layout from "src/components/Layout/Layout";
import Loading from "src/components/Loading/Loading";
import Table from "src/components/Table/Table";
import Modal from "src/components/Modal/Modal";
import Button from "src/components/Button/Button";
import Helper from "src/common/Helper";
import Form from "src/forms/Form";
import Input from "src/forms/primitives/Input";
import PSelect from "src/forms/primitives/PSelect";

import {n_success} from "../actions/NotificationActions";
import {m_confirm} from "../actions/ModalActions";

import QueryParams from "src/common/QueryParams";
import Api from "src/common/Api";

const section = sections_get("users");

class PageUsers extends Component {
    constructor(props) {
        super(props);
        let query = QueryParams.parse(props.location.pathname);
        this.state = {
            throw_err: false,

            customers: [],
            users: [],
            loading: false,
            filter_show_history: query.sh === "1" || false,
            filter_search_value: query.q || "",
            filter_sort: query.sort || "userId",
            add: false,
            edit: false,
            userName: "",
            userNick: "",
            userPass: "",
            userCustomers: [],
        };

        this.fetch = this.fetch.bind(this);
        this.save = this.save.bind(this);
        this.add = this.add.bind(this);
        this.delete = this.delete.bind(this);

        this.renderUsers = this.renderUsers.bind(this);
        this.renderSearch = this.renderSearch.bind(this);
        this.renderActions = this.renderActions.bind(this);
    }

    componentDidMount() {
        this.fetch();
    }

    componentDidUpdate(prevProps, prevState) {
        let last_param_page_json = JSON.stringify(QueryParams.parse(prevProps.location.pathname)),
            new_param_page_json = JSON.stringify(QueryParams.parse(this.props.location.pathname));

        if(last_param_page_json !== new_param_page_json && !this.state.loading) {
            this.fetch();
        }
    }

    fetch() {
        this.setState(
            {loading: true},
            () => Promise.all([
                Api.getUsers(),
                Api.getCustomers(),
            ])
            .then(response => {
                const users = response[0],
                    customers = response[1],
                    users_map = Helper.create_map(users, "userId"),
                    customers_map = Helper.create_map(customers, "customerId");
                this.setState({
                    users: users,
                    customers: customers.map(customer => ({label: customer.customerName, value: customer.customerId, ...customer})),
                    users_map,
                    customers_map
                });
            })
            .finally(() => this.setState({loading: false}))
        );
    }

    renderSearch() {
        return <form
            className="w-100p mt-2r"
            onSubmit={this.search}
        >
            <Layout.Toolbar>
                <input
                    className="flex-auto input"
                    value={this.state.order_item_search_value}
                    onChange={e => this.setState({order_item_search_value: e.target.value})}
                    placeholder="Search"
                />
                <Button className="mt-0">Search</Button>
            </Layout.Toolbar>
        </form>
    }

    delete({userName, userId}) {
        m_confirm("Delete user \"" + userName + "\"?")
            .then(() => this.setState(
                {loading: true},
                () => Api.deleteUser(userId)
                .finally(() => {
                    n_success("Deleted");
                    this.fetch();
                })
            ))
    }

    save(row) {
        const userCustomers = this.state.userCustomers.map(({customerId}) => customerId),
            {userName, userNick, userPass} = this.state;
        if(
            userName.length > 0
            && userNick.length > 0
            && userPass.length > 0
        ) this.setState(
            {loading: true},
            () => Api.changeUser(row.userId, {userName, userNick, userPass, userCustomers})
            .finally(
                () => {
                    this.setState({edit: false}, this.fetch)
                    n_success("Saved");
                }
            )
        );
    }

    renderActions(row) {
        const buttonClass = "link text-body";
        return <Layout.Toolbar>
            { this.state.edit === row.userId
                ? <>
                    <button className={buttonClass} onClick={() => this.save(row)}>Save</button>
                    <button className={buttonClass} onClick={() => this.setState({edit: false})}>Cancel</button>
                </>
                : <button
                    className={buttonClass}
                    onClick={() => this.setState({
                        edit: row.userId,
                        userName: row.userName,
                        userNick: row.userNick,
                        userPass: row.userPass,
                        userCustomers: row.userCustomers.map(option => this.state.customers[this.state.customers_map[option]]),
                    })}
                >
                    Edit
                </button>
            }
            <Layout.Space/>
            <button className={buttonClass +" color-warning"} onClick={() => this.delete(row)}>Delete</button>
        </Layout.Toolbar>
    }

    renderUsers() {
        return <Table
            data={this.state.users}
            noDataMessage="No data"
            columnAccessorId="userId"
            columns={[
                {
                    Header: "Id",
                    classTh: "text-nowrap",
                    accessor: "userId",
                },
                {
                    Header: "Username",
                    classTh: "text-nowrap",
                    accessor: "userName",
                    Cell: row => {
                        if(this.state.edit === row.userId) return <Input onChange={e => this.setState({userName: e.target.value})} value={this.state.userName}/>
                        return row.userName
                    }
                },
                {
                    Header: "Nick",
                    classTh: "text-nowrap",
                    accessor: "userNick",
                    Cell: row => {
                        if(this.state.edit === row.userId) return <Input onChange={e => this.setState({userNick: e.target.value})} value={this.state.userNick}/>
                        return row.userNick
                    }
                },
                {
                    Header: "Password",
                    classTh: "text-nowrap",
                    accessor: "userPass",
                    Cell: row => {
                        if(this.state.edit === row.userId) return <Input onChange={e => this.setState({userPass: e.target.value})} value={this.state.userPass}/>
                        return row.userPass
                    }
                },
                {
                    Header: "Customers",
                    classTh: "w-33p",
                    accessor: "userCustomers",
                    Cell: row => {
                        if(this.state.edit === row.userId) return <PSelect
                            className="w-100p"
                            value={this.state.userCustomers}
                            options={this.state.customers}
                            onChange={userCustomers => this.setState({userCustomers})}
                            isMulti={true}
                        />
                        return row.userCustomers
                            .map(customerId => this.state.customers[this.state.customers_map[customerId]].customerName)
                            .join(", ")
                    }
                },
                {
                    Header: "Actions",
                    classTh: "w-15r",
                    accessor: "edit",
                    sortable: false,
                    Cell: row => this.renderActions(row)
                }
            ]}
        />
    }

    add(fields) {
        this.setState(
            {loading: true},
            () => Api.createUser(fields)
            .finally(
                () => this.setState({add: false}, this.fetch)
            )
        );
    }

    renderAdd() {
        return <Modal show={this.state.add} onClose={() => this.setState({add: false})}>
            <div className="w-40r ph-2r pb-2r">
                <h2>Add new user</h2>
                <Form onValidSubmit={fields => this.add(fields)}>
                    <Form.Fields>
                        {[
                            {type: "text", name: "userName", label: "Name", required: true},
                            {type: "text", name: "userNick", label: "Nick", required: true},
                            {type: "password", name: "userPass", label: "Password", required: true},
                            {type: "select", name: "userCustomers", label: "Customers", isMulti:true, options: this.state.customers},
                        ]}
                    </Form.Fields>
                    <Button className="mt-2r">Add user</Button>
                </Form>
            </div>
        </Modal>
    }

    render() {
        return (
            <Page
                title={section.title}
                section={section.code}
                className="pb-4r"
            >
                <Layout.Row>
                    <h1>{section.title}</h1>
                    <Layout.Space/>
                    <div className="mt-a">
                        <Button onClick={() => this.setState({add: true})}>Add new</Button>
                    </div>
                </Layout.Row>
                <Layout.Col>
                    <div className="w-100p pos-relative">
                        {this.renderUsers()}
                    </div>
                </Layout.Col>
                {this.renderAdd()}
                {this.state.loading && <Loading place="window"/>}
            </Page>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    count: state.global.count,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageUsers))
