import React from 'react';

class Input extends React.Component {
    render() {
        let {className, ...otherProps} = this.props;

        return <input
            className={className + " input"}
            size="1"
            {...otherProps}
        />
    }
}

Input.defaultProps = {
    className: "",
};

export default Input;
